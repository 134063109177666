import { createRouter, createWebHistory } from "vue-router";
import Website from '@/views/website.vue';

// 公共路由规则
const routes = [
    {
        path: '/', 
        name: 'index',
        component: () => import('@/views/index.vue') 
    },
    {
        path: '/website', 
        name: 'website',
        component: Website, 
    },

]

// 创建路由
const router = createRouter({
    // 使用 history 模式
    history: createWebHistory(),
    // 路由规则
    routes
})

export default router;