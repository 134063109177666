<template>
  <div id="app">
    <el-row :gutter="12" class="row">
      <el-col :span="6" v-for="product in products" :key="product.id">
        <el-card class="elcard" :body-style="{ padding: '5px' }"  shadow="hover" @click="redirectTo(product.id,product.url)">
          <div class="card-content">
            <div class="icon">
              <img :src="product.icon" />
            </div>
            <div class="content">
                {{ product.name }}
            </div>
          </div>
          <div class="description">
            <span v-if="product.description">{{ product.description }}</span>
            <span v-else>暂无描述</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { inject } from "vue";
import axios from "axios";
export default {
  name: "app",
  props: {
    typeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colSpan: 6, // 默认的 col-span 值
      products: [], // 存储接口返回的商品数
      jsonParam: {
        websiteTypeId : null
      },
      apiUrl: null,
    };
  },
  created() {
    this.websiteList();
    this.apiUrl = inject("API_URL");
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    filteredProducts() {
      return this.products.filter((product) => product.id === this.id);
    },
  },
  methods: {
    handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        this.colSpan = 24; // 当窗口宽度小于768px时，设置 col-span 为24
      } else if (screenWidth < 992) {
        this.colSpan = 12; // 当窗口宽度小于992px时，设置 col-span 为12
      } else if (screenWidth < 1200) {
        this.colSpan = 8; // 当窗口宽度小于1200px时，设置 col-span 为8
      } else {
        this.colSpan = 6; // 当窗口宽度大于等于1200px时，设置 col-span 为6
      }
    },
    websiteList() {
      this.jsonParam.websiteTypeId = this.typeId;
      axios
        .post(inject("API_URL") + "/api/website/list",this.jsonParam,{
          headers: {
            deviceCode: localStorage.getItem('jsl-clid'),
          },
        })
        .then((response) => {
          if (response.data.data !== undefined && response.data.data && response.data.data.length !== 0) {
            this.products = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    redirectTo(id,url) {
      this.jsonParam.websiteId = id;
      axios
        .post(this.apiUrl + "/api/website/click",this.jsonParam,{
          headers: {
            deviceCode: localStorage.getItem('jsl-clid'),
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
      window.open(url, "_blank");
    }
  },
};
</script>
<style scoped>
.card-content {
  display: flex;
  align-items: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.icon {
  margin-right: 5px;
}

.icon img {
  width: 25px;
  height: 25px;
}

.elcard {
  margin-bottom: 13px;
  cursor: default;
}

.elcard:hover {
  cursor: pointer;
}

.description {
  margin-left: 5px;
  font-size: 11px;
  color: #A2A2A2;
}

.content {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.description span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

</style>
