import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router' // 引入路由实例

const app = createApp(App)

app.use(ElementPlus)
app.use(router)
// 设置全局变量
app.provide('API_URL', 'https://xzjapi.xiaozhujuan.vip')
app.mount('#app')
